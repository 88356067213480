$g: #567947;
.background-img {
  position: relative;
  main{
    z-index: 0;
  }
  .return-button {
    z-index: 1;
  }
}
.illust-mode {
  position: relative;
  .item-look {
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    height: 70%;
    img {
      height: 100%; //w:h=595:839
    }
  }
  .item-title-container {
    position: absolute;
    left: 50%;
    top: 75%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    .item-title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90vw;
      height: calc(90vw * 80 / 291);
      background-size: cover;
      p {
        font-size: 19px;
        color: $g;
      }
    }
  }
}

@media screen and (min-width: 400px) {
  .illust-mode {
    .item-title-container {
      .item-title {
        width: 360px;
        height: calc(360px * 80 / 291);
      }
    }
  }
}
