.return-button {
  width: 40px;
  height: 40px;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  row-gap: 50px;
  margin-top: 50px;
  &:last-child {
    padding-bottom: 100px;
  }

  .card {
    position: relative;
    width: 35vw;
    max-width: 170px;
    height: 150px;
    border: 3px solid #567947;
    background-color: #fff;
    border-radius: 20px;

    .card-img {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100px * 595 / 839);
      height: 100px;
    }
    .card-name {
      position: absolute;
      top: 80%;
      transform: translateY(-50%);
      text-align: center;
      width: 100%;
      color: #567947;
      font-size: 1.2rem;
      padding-bottom: 5%;
    }
  }
}

@media screen and (min-width: 600px) {
  .cards-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1024px) {
  .cards-container {
    grid-template-columns: repeat(4, 1fr);
  }
}
